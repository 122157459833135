<template>
    <div class="row">
        <template v-if="item.debit == null">
            <div class="col-lg-6 col-md-12 mb-1 mb-sm-0">
                <label for="formFile" class="form-label">Transfer from/Paid from</label>
                <v-select
                    placeholder="Select Cash & Bank Account"
                    v-model="item.account_head_id"
                    :options="accountHeads" 
                    label="name"
                    :reduce="name => name.id"
                />
            </div>
            <div class="col-lg-6 col-md-12">
                <label for="formFileMultiple" class="form-label">Description</label>
                <input
                    v-model="item.note" 
                    type="text" class="form-control"
                    placeholder="Description"
                >
            </div>
        </template>
        <template v-else>
            <div class="col-lg-6 col-md-12 mb-1 mb-sm-0">
                <label for="formFile" class="form-label">Transfer to/Received in</label>
                <v-select
                    placeholder="Select Cash & Bank Account"
                    v-model="item.account_head_id"
                    :options="accountHeads" 
                    label="name"
                    :reduce="name => name.id"
                />
            </div>
            <div class="col-lg-6 col-md-12">
                <label for="formFileMultiple" class="form-label">Description</label>
                <input 
                    v-model="item.note"
                    type="text" class="form-control"
                    placeholder="Description"
                >
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'AddFormElement',
    props: {
        item: {
            type: Object
        },
        index: {
            type: Number
        },
        accountHeads: {
            type: Array
        }
    }
}
</script>
<style scoped>
.journal-line{
        border: 1px solid #f0f2f5;
    }
    .journal-line:hover{
        background: #f0f2f5;
    }
</style>
