<template>
    <div
        v-if="isOpen"
        class="modal fade show"
        aria-labelledby="addNewCardTitle"
        style="display: flex; background: #1c1d1c33; align-content: center;"
    >
        <div class="width-50-per mx-auto" style="margin-top: 100px; max-height: 50vh;">
            <div class="card">
                <div class="card-header border-bottom d-flex justify-content-between">
                    <div class="card-title">Validation Failed</div>
                    <a href="#" style="font-size: 1.5em" title="close modal" @click.prevent="closeModal">x</a>
                </div>
                <div class="card-body mt-2 w-100">
                    <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>

                    <div v-else class="alert alert-danger p-3">
                        {{ message }} Would you like to use <b>{{voucherNumber}}</b> as new number?
                    </div>
                </div>
                <div class="card-footer text-right">
                    <button class="btn btn-primary" @click="submit">Ok</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>

import {ref} from "vue";
import {generateVoucherNumber} from "@/services/utils/voucherNumberGenerator";

const $props = defineProps({
    voucherQuery: String
})

const $emit = defineEmits(['onPressOk'])

const isOpen = ref(false)
const loading = ref(false)
const message = ref('')
const voucherNumber = ref('')

const closeModal = () => {
    isOpen.value = false;
    message.value = ''
}
const submit = () => {
    $emit('onPressOk');
    closeModal();
}
const openModal = (mess) => {
    isOpen.value = true;
    loading.value = true;
    generateVoucherNumber($props.voucherQuery)
        .then(voucher => {
            voucherNumber.value = `${voucher.prefix}-${voucher.serial}`;
        })
        .finally(() => {
            loading.value = false;
        })
    message.value = mess
}

defineExpose({
    closeModal,
    openModal
})

</script>
